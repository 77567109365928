import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  TextFieldCluedContainer,
  TextFieldCluedHighlighted,
  TextFieldCluedNotHighlighted,
  TextFieldCluedOutterContainer,
  TextFieldContainer,
  TextFieldInput,
  TextFieldSingleSuggestion,
  TextFieldSingleSuggestionText,
  TextFieldSingleSuggestionTextHighlighted,
  TextFieldSuggestionsContainer,
} from "./TextField.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectGame, selectSongs } from "../../store/selectors/gameSelectors";
import { fetchListSongs } from "../../store/actions/game/gameActions";
import {
  // TIMEOUT_FIELD_STATE,
  OVER_FIELD_STATE,
  CORRECT_FIELD_STATE,
  GAVEUP_FIELD_STATE,
  TIMEOUT_FIELD_STATE,
  WRONG_FIELD_STATE,
} from "../../constants/fieldStates";
import { experimentalStyled } from "@mui/material";

const TextField = (props) => {
  const containerRef = useRef(null);
  const cluedRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const dispatch = useDispatch();
  const songs = useSelector(selectGame);
  const songAnswers = useSelector(selectSongs);
  const [placeholder, setPlaceholder] = useState("");
  const [isStarted, setIsStarted] = useState(true);

  const values = useMemo(() => {
    if (!songs) return [];
    return songAnswers;
  }, [songAnswers]);
  useEffect(() => {
    if (props?.searchSongs)
      dispatch(
        fetchListSongs({
          searchTerm: props?.value,
        })
      );
  }, [props?.searchSongs, props?.value]);
  useEffect(() => {
    if (props?.currentIndex === props?.songIndex) {
      setIsFocused(true);
      if (props?.fieldState !== TIMEOUT_FIELD_STATE) {
        setPlaceholder("Answer here to stop the clock");
      }
    } else if (!props?.isPlaying) {
      setIsFocused(false);
      setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
    }
  }, [props.currentIndex]);

  const handleFocusField = () => {
    if (
      //props?.fieldState === TIMEOUT_FIELD_STATE ||
      props?.fieldState === OVER_FIELD_STATE ||
      props?.fieldState === CORRECT_FIELD_STATE ||
      props?.fieldState === GAVEUP_FIELD_STATE
    ) {
      return;
    }
    if (placeholder === "Try Again or Move On") {
      props?.setTextValue("");
      props?.setFieldState("INITIAL_FIELD_STATE");
      setPlaceholder("Answer here to stop the clock");
    }
    props?.onFocus();
    setIsFocused(true);
    props.setCurrentSongUrl(props?.songUrl);
    props?.setCurrentIndex(props?.songIndex);
    props?.setPlayerStatus(true);
    if (props?.pauseMusic) {
      setPlaceholder("");
      props.pauseMusic();
    }
  };

  const handleGuessSong = (event, songName) => {
    setIsFocused(false);
    props?.handleGuessSong(songName);
  };

  // const checkFocus = useCallback(
  //   (event) => {
  //     if (
  //       containerRef.current &&
  //       !containerRef.current.contains(event.target)
  //     ) {
  //       // setIsFocused(false);
  //       setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
  //     }
  //   },
  //   [containerRef?.current]
  // );

  // useEffect(() => {
  //   document.addEventListener("mousedown", checkFocus.bind(this));
  //   return () => {
  //     document.removeEventListener("mousedown", checkFocus.bind(this));
  //   };
  // }, []);
  // console.dir(cluedRef.current);
  useEffect(() => {
    //if isLoaded from parent is false attribute readonly is set and that will prevent mobile keyboard to shows up, and once that song is played state isLoaded will become true!!!
    const input = containerRef.current.children[0].children[0].children[0];
    if (
      !props?.readOnly ||
      // props?.value === 'Time up! Pass or X to reveal (for -3 points)'
      //props?.fieldState === TIMEOUT_FIELD_STATE ||
      props?.fieldState === OVER_FIELD_STATE ||
      props?.fieldState === CORRECT_FIELD_STATE ||
      props?.fieldState === GAVEUP_FIELD_STATE
    ) {
      input.setAttribute("readonly", "true");
    } else {
      input.removeAttribute("readonly");
    }
  }, [props?.readOnly, props?.value]);
  const onInput = (e) => {
    props?.changeTextValue(e.target.value);
  };
  // useEffect(() => {
  //   if(isFocused){
  //     console.log('fokusiran')
  //   }
  //   else{
  //     console.log('nije fokusiran')
  //   }
  // },[isFocused])//after time's up if focus on input and unfocus without trying to hit the answer input value becomes an empty string...here is the key of fixing that bug...we need to send to the parent somehow prop isFocused and mix that with global state of fields or to put field state from parent to this child component!!!we will see
  useEffect(() => {
    // console.log("props.isPlaying", props.isPlaying);
    // console.log("props?.currentIndex", props?.currentIndex);
    if (props.isPlaying && props?.currentIndex === props?.songIndex) {
      setPlaceholder("Answer here to stop the clock");
    } else {
      setPlaceholder("SONG " + (Number(props.songIndex) + 1).toString());
      setIsStarted(false);
    }
    if (props?.fieldState === TIMEOUT_FIELD_STATE) {
      setPlaceholder("Guess, move on or X to reveal (+20 secs)");
    }
    if (props?.fieldState === GAVEUP_FIELD_STATE) {
      setPlaceholder("Try Again or Move On");
    }
    if (props?.fieldState === WRONG_FIELD_STATE) {
      setPlaceholder("Try Again or Move On");
    }
    // This is for testing UI
  }, [props?.readOnly, props?.fieldState, props?.currentIndex]);
  useEffect(() => {
    if (props?.isPlaying && props?.currentIndex === props?.songIndex) {
      setIsFocused(true);
      setPlaceholder("Answer here to stop the clock");
    }
  }, [props.isPlaying]);
  return (
    <TextFieldContainer
      ref={containerRef}
      onFocus={handleFocusField}
      backgroundColor={props?.backgroundColor}
      className={props?.className}
      gaveUp={props?.fieldState === GAVEUP_FIELD_STATE}
      correct={props?.fieldState === CORRECT_FIELD_STATE}
      wrong={
        props?.fieldState === WRONG_FIELD_STATE ||
        props?.fieldState === TIMEOUT_FIELD_STATE
      }
      is_time_out={props?.fieldState === TIMEOUT_FIELD_STATE}
      isFocused={isFocused}
    >
      {props?.clue && (
        <TextFieldCluedOutterContainer onClick={() => props.activatePlayer()}>
          <TextFieldCluedContainer
            onKeyDown={(e) => {
              e.preventDefault();
              return false;
            }}
            // contentEditable
            ref={cluedRef}
          >
            <TextFieldCluedNotHighlighted $unSolved={props?.unSolved}>
              {props?.clue?.[0]}
            </TextFieldCluedNotHighlighted>
            <TextFieldCluedHighlighted>
              {props?.clue?.[1]}
            </TextFieldCluedHighlighted>
            <TextFieldCluedNotHighlighted $unSolved={props?.unSolved}>
              {props?.clue?.[2]}
            </TextFieldCluedNotHighlighted>
          </TextFieldCluedContainer>
        </TextFieldCluedOutterContainer>
      )}
      {!props?.clue && (
        <TextFieldInput
          // placeholder={props?.songIndex === 0 &&
          // !props?.readOnly ? "Answer" : ""}
          placeholder={placeholder}
          $unSolved={props?.unSolved}
          isFocused={isFocused}
          gaveUp={props?.fieldState === GAVEUP_FIELD_STATE}
          correct={props?.fieldState === CORRECT_FIELD_STATE}
          {...props}
          inputProps={{ autocomplete: "off" }}
          onChange={onInput}
        />
      )}
      {props?.value?.length > 0 && isFocused && !props?.hideSuggestions && (
        <TextFieldSuggestionsContainer
          onClick={(event) => event.preventDefault()}
        >
          {values?.map((singleValue) => {
            if (
              !singleValue?.toLowerCase()?.includes(props?.value?.toLowerCase())
            )
              return;
            const firstValue = singleValue?.slice(
              0,
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase())
            );
            const secondValue = singleValue?.slice(
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()),
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()) +
                props?.value?.toLowerCase()?.length
            );
            const thirdValue = singleValue?.slice(
              singleValue?.toLowerCase().indexOf(props?.value?.toLowerCase()) +
                props?.value?.toLowerCase()?.length
            );
            return (
              <TextFieldSingleSuggestion
                key={singleValue}
                onClick={(event) => handleGuessSong(event, singleValue)}
              >
                <TextFieldSingleSuggestionText>
                  {firstValue}
                </TextFieldSingleSuggestionText>
                <TextFieldSingleSuggestionTextHighlighted>
                  {secondValue}
                </TextFieldSingleSuggestionTextHighlighted>
                <TextFieldSingleSuggestionText>
                  {thirdValue}
                </TextFieldSingleSuggestionText>
              </TextFieldSingleSuggestion>
            );
          })}
        </TextFieldSuggestionsContainer>
      )}
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  backgroundColor: PropTypes.string,
  handleGuessSong: PropTypes.func,
  onFocus: PropTypes.func,
  setTextValue: PropTypes.func,
  hideSuggestions: PropTypes.bool,
  clue: PropTypes.bool,
  searchSongs: PropTypes.number,
  solved: PropTypes.bool,
  unSolved: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  fieldState: PropTypes.string,
  songIndex: PropTypes.number,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  activatePlayer: PropTypes.func,
  songUrl: PropTypes.string,
  setCurrentSongUrl: PropTypes.func,
  setPlayerStatus: PropTypes.func,
  pauseMusic: PropTypes.func,
  changeTextValue: PropTypes.func,
  setFieldState: PropTypes.func,
  isPlaying: PropTypes.bool,
};

export default TextField;

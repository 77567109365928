import { Box, TextField } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as GameOver } from "../../../assets/images/svg/game-over.svg";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye.svg";
import { ReactComponent as linkHintIcon } from "../../../assets/images/svg/link-hint.svg";
import { ReactComponent as linkGiveUpIcon } from "../../../assets/images/svg/circle-command.svg";

export const LinkContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  margin-top: 24px;
`;
export const LinkTextFieldContainer = styled(Box)`
  position: relative;
  /* width: 100vw; */
  flex-basis: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  /* background-color: white; */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
export const LinkTextInputField = styled(TextField)`
  width: 100%;
  background-color: #ffffff11;
  border-radius: 11px;
  font-family: Calibri, sans-serif;
  outline: 0;
  font-size: 16px;
  position: relative;
  font-size: 18px;
  overflow: hidden;
  /* padding: 13px 14px; */
  /* &:empty {
    text-align: center;
  }
  &:focus {
    text-align: left;
  }
  &:empty:not(:focus):before {
    font-family: Calibri, sans-serif;
    content: attr(placeholder);
    text-align: center;
    font-size: 18px;
    padding: 11px 0;
    opacity: 1;
    color: black;
  } */
  & * {
    outline: 0;
    /* padding: 0; */
    font-size: 16px;
    border: 0;
    max-height: 33px;
    color: white;
  }
  & legend {
    display: none;
  }
  & fieldset {
    border-width: 0;
    outline: 0;
  }
  & input {
    /* background-color: ${(props) => props?.bgcolor}; */
    background-color: ${(props) =>
      props?.fieldState === "GAVEUP_FIELD_STATE"
        ? "#FF0000"
        : props?.fieldState === "CORRECT_FIELD_STATE"
        ? "#1EFF15"
        : props?.can_try_again
        ? "#FF6453"
        : "#fff"};
    /* color: #000; */
    color: ${(props) =>
      props?.fieldState === "GAVEUP_FIELD_STATE" ? "#fff" : "#000"};
    /* color: ${(props) => (props?.bgcolor ? "white" : "white")}; */
    /* padding: 11px 14px; */
    border: 0 !important;
    text-align: center;
    font-weight: ${(props) => (props.canAnswer ? "600" : "unset")};
  }
  input::placeholder {
    padding: 11px 0;
    opacity: ${(props) => (props.active ? "1" : "0.5")};
    color: #000;
    text-align: center;
  }
`;
export const GoButton = styled(Box)`
  padding: 4px 6px;
  border-radius: 44%;
  background-color: #05bc5a;
  font-size: 18px;
  position: absolute;
  top: 10px;
  right: 32px;
  cursor: pointer;
`;

export const TextFieldSuggestionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 150;
  width: 100%;
  height: fit-content;
  max-height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: black;
  @media (max-height: 910px) {
    top: initial;
    bottom: 100%;
  }
`;
export const TextFieldSingleSuggestion = styled(Box)`
  width: 100%;
  min-height: 48px;
  height: 48px;
  border: 1px solid ${selectedTheme.colors.suggestionsBorder};
  font-family: Calibri, sans-serif;
  display: flex;
  align-items: center;
  padding-left: 8px;
  &:hover {
    background-color: ${selectedTheme.colors.suggestionsBorder};
    cursor: pointer;
  }
`;
export const TextFieldSingleSuggestionText = styled.pre`
  font-family: Calibri, sans-serif;
  color: white;
  display: inline-block;
`;
export const TextFieldSingleSuggestionTextHighlighted = styled.pre`
  font-family: Calibri, sans-serif;
  display: inline-block;
  color: white;
  background-color: ${selectedTheme.colors.suggestionsHighlight};
`;

export const FinishingText = styled(Box)`
  display: inline-block;
  font-family: Calibri, sans-serif;
  color: white !important;
  font-size: 20px;
  text-align: center;
  max-width: 300px;
`;
export const GameOverIcon = styled(GameOver)`
  width: 50px;
  height: 50px;
  & path {
    fill: red;
  }
`;
export const EyeIcon = styled(Eye)`
  width: 34px;
  position: relative;
  top: 7px;
`;
export const HintIcon = styled(linkHintIcon)`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
export const GiveUpIcon = styled(linkGiveUpIcon)`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
export const LinkCommandContainer = styled(Box)`
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: 16px;
`;
export const LinkCommand = styled("p")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  margin-top: 10px;
  font-size: 16px;
  background-color: ${(props) =>
    props.type === "released"
      ? "#B6DBA2"
      : props.type === "red"
      ? "#FF0000"
      : props.type === "light-red"
      ? "#FFB5AD"
      : "#FFF"};
  color: ${(props) =>
    props.type === "released"
      ? "#6352dc"
      : props.type === "red"
      ? "#FFF"
      : "#000"};
  border-radius: 11px;
  text-align: center;
  font-style: ${(props) => (props.type === "released" ? "italic" : "unset")};
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  font-weight: 600;
`;

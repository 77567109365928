import React from "react";
import PropTypes from "prop-types";
import "./Sponsor.scss";

const Sponsor = (props) => {
  return (
    <div className="sponsor-wrapper">
      {props.graphics.footerText}
      <img src={props.graphics.footerLogoUrl} />
    </div>
  );
};

Sponsor.propTypes = {
  graphics: PropTypes.object,
};

export default Sponsor;

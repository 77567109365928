import React, {
  forwardRef,
  // useCallback,
  useEffect,
  useLayoutEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  EyeIcon,
  GuessSongFieldContainer,
  GuessTextField,
  MelodyIcon,
  PlayerCommandsContainer,
  InvisiblePlayerWrapper,
} from "./GuessSongField.styled";
// import PlayingDisc from "../MusicDisc/PlayingDisc/PlayingDisc";
import PlayerController from "../PlayerController/PlayerController"; //replacement for playing disc
import CircleCommand from "../CircleCommand/CircleCommand";
import DiscLosingProgress from "../DiscLosingProgress/DiscLosingProgress";
// import {
//   GREEN_DISC,
//   ORANGE_DISC,
//   RED_DISC,
//   WHITE_DISC,
// } from "../../constants/discColors";
import {
  CORRECT_FIELD_STATE,
  INITIAL_FIELD_STATE,
  OVER_FIELD_STATE,
  WRONG_FIELD_STATE,
  GAVEUP_FIELD_STATE,
  TIMEOUT_FIELD_STATE,
} from "../../constants/fieldStates";
import { useDispatch } from "react-redux";
import { fetchGuessSong } from "../../store/actions/game/gameActions";
import selectedTheme from "../../themes";

//firebase Analytics
import { logEvent } from "firebase/analytics";
import { analytics } from "../../index";

const TIMEOUT_DURATION = 5000;

const GuessSongField = forwardRef((props, ref) => {
  const [textValue, setTextValue] = useState(""); //song input
  // const fieldLives = useRef(3);//3 lives per song
  // const guessLives = useRef(3) //3 lives for guessing
  const [fieldState, setFieldState] = useState(INITIAL_FIELD_STATE); //song field global state
  const [isLoaded, setIsLoaded] = useState(false); //rejected guessing a song without this...isLoaded means - song was played before guessing
  // const [losingTimeout, setLosingTimeout] = useState(null);//setting timeout which calls lose one life after TIMEOUT_DURATION
  // const losingTimeout = useRef(null)---------------------------------------------DEPRECATED---------------------------------------------------------
  const [song, setSong] = useState(); //fetched song using props?.songUrl
  const [playerStatus, setPlayerStatus] = useState(false); //using in PlayerController just to know what to show "play button" or "pause button"
  const [songsLeftTime, setSongsLeftTime] = useState(
    () =>
      //evidence of song and it's left time
      (TIMEOUT_DURATION / 1000) * 3
  );

  const songTime = useRef(0);
  const leftTime = useRef((TIMEOUT_DURATION / 1000) * 3);
  const [rewindTime, setRewindTime] = useState(0);
  // const lifeLoseSwitcher = useRef(false)
  const dispatch = useDispatch(); //fetching answer for that song
  const clue = useMemo(() => {
    //separating keyword(clue word) which will be sended to guessTextField component
    if (!props?.cluesRevealed) return null;
    if (
      fieldState === CORRECT_FIELD_STATE ||
      // fieldState.current === OVER_FIELD_STATE ||
      fieldState === GAVEUP_FIELD_STATE //trying to involve new field state in order to solve gave up solution!!!
    ) {
      return [
        props?.songName?.slice(
          0,
          props?.songName?.toLowerCase().indexOf(props?.hint?.toLowerCase())
        ),
        props?.songName?.slice(
          props?.songName?.toLowerCase().indexOf(props?.hint?.toLowerCase()),
          props?.songName?.toLowerCase().indexOf(props?.hint?.toLowerCase()) +
            props?.hint?.toLowerCase()?.length
        ),
        props?.songName?.slice(
          props?.songName?.toLowerCase().indexOf(props?.hint?.toLowerCase()) +
            props?.hint?.toLowerCase()?.length
        ),
      ];
    }
    return null;
  }, [props?.cluesRevealed, fieldState]);

  const titleOrArtistClue = useMemo(() => {
    //separating keyword(clue word) which will be sended to guessTextField component
    if (!props?.titleOrArtistCluesRevealed) return null;
    if (
      fieldState === CORRECT_FIELD_STATE ||
      // fieldState.current === OVER_FIELD_STATE ||
      fieldState === GAVEUP_FIELD_STATE //trying to involve new field state in order to solve gave up solution!!!
    ) {
      const [title, artist] = props?.songName?.split(" - ") || [];
      if (props?.titleOrArtistHint === "Title") {
        return ["", title, " - " + artist];
      } else if (props?.titleOrArtistHint === "Artist") {
        return [title + " - ", artist, ""];
      }
    }
    return null;
  }, [props?.titleOrArtistCluesRevealed, fieldState]);

  // const loseLife = () => {//if user have missed song name he loses one life or if TIMEOUT_DURATION is ended
  //---------------------------------------------DEPRECATED---------------------------------------------------------
  //   if (fieldLives?.current > 0 && fieldState.current !== CORRECT_FIELD_STATE) {
  //     if (fieldLives?.current === 1) loseField();
  //     fieldLives.current = fieldLives?.current - 1;
  //     props?.loseLife();
  //   }
  // };
  const giveUp = () => {
    //if user gives up and reveals song name, he loses all 3 lives on that song
    // if(fieldState.current === TIMEOUT_FIELD_STATE || fieldState.current === OVER_FIELD_STATE || fieldState.current === INITIAL_FIELD_STATE){
    //   for (let i = 0; i < 3; i++) {---------------------------------------------DEPRECATED---------------------------------------------------------
    //     props?.loseLife();
    //     if(i===2){
    //       loseField()
    //     }
    //   }
    // }
    // if (---------------------------------------------DEPRECATED---------------------------------------------------------
    //   fieldState.current !== CORRECT_FIELD_STATE &&
    //   fieldState.current !== OVER_FIELD_STATE &&
    //   fieldState.current !== GAVEUP_FIELD_STATE &&//ovde dodati posebnu logiku za timeout field
    //   !props?.disabled
    // ) {
    //   let remainingLives = fieldLives?.current;
    //   for (let i = 0; i < remainingLives; i++) {
    //     loseLife();
    //   }
    // }
    setFieldState(GAVEUP_FIELD_STATE);
    setTextValue(props?.songName);
    song?.pause();
    props?.setIsPlaying(false);
    props.giveUpScoreTimers(props.songIndex);
    props.handleClearInterval();
    logEvent(analytics, "select_content_give_up", {
      content_type: "give_up_song",
      item_id: `${props?.songName}`,
      song_duration: `${song?.currentTime}`,
    });
    //when scoreTimer setup this action will add 20 secunds to the timer!!!!!!!!
  };

  // useEffect(() => {//tracking lives and separate logic for two states TIMEOUT AND OVER FIELD STATE
  //   if(leftTime.current < 0.5){---------------------------------------------DEPRECATED---------------------------------------------------------
  //     fieldState.current = TIMEOUT_FIELD_STATE
  //     setTextValue('Time up! Pass or X to reveal (for -3 points)');
  //     setSongsLeftTime(0)
  //     clearTimeout(losingTimeout.current);
  //     losingTimeout.current = null;
  //     setPlayerStatus(false)
  //     console.log('radis li ti sta???')
  //     props?.setIsPlaying(false)//spustiti na dete field state kako bi zabranio pustanje pesme nakon isteka vremena!!! i napraviti jednu play kontrolu zasebnu koja ce na odredjene field state-ove dozvoliti poseban play button koji ce pustiti pesmu radi zabave
  //   }
  // },[fieldLives.current]) //OVDE CEMO URADITI LOGIKU ZA TIME OUT FIELD STATE KOJI CE DA PRATI DA LI JE VREME PESME ISTEKLO!!!------------------------------

  // const loseField = () => {//if user loses single song game
  //   // fieldState.current = OVER_FIELD_STATE;---------------------------------------------DEPRECATED---------------------------------------------------------
  //   // setTextValue(props?.songName);
  //   song?.pause();
  //   clearTimeout(losingTimeout.current);
  //   losingTimeout.current = null;
  //   props?.setIsPlaying(false)
  // };

  // const discColor = useMemo(() => {---------------------------------------------DEPRECATED---------------------------------------------------------
  //   if (
  //     fieldState.current === WRONG_FIELD_STATE ||
  //     fieldState.current === OVER_FIELD_STATE
  //   )
  //     return RED_DISC;
  //   if (fieldState.current === CORRECT_FIELD_STATE) return GREEN_DISC;
  //   if (!isLoaded) return WHITE_DISC;
  //   return ORANGE_DISC;
  // }, [fieldState.current, isLoaded]);
  const playerControllerStatus = useMemo(() => {
    //this is used for player controller to knows what icons to show...if song field is over it shows checkICON and if it's not controller ICONS
    if (
      //fieldState.current === OVER_FIELD_STATE ||---------------------------------------------DEPRECATED---------------------------------------------------------
      fieldState === CORRECT_FIELD_STATE ||
      fieldState === GAVEUP_FIELD_STATE
    )
      return false;
    return true;
  }, [fieldState]);

  const pauseSong = () => {
    //if song field is not disabled and if song is NOT paused it will be paused after this function
    if (!props?.disabled) {
      if (!song.paused) {
        song.pause();
        // clearTimeout(losingTimeout.current);
        // losingTimeout.current = null;
        props?.setIsPlaying(false);
        setPlayerStatus(false);
        props.handleClearInterval();
      }
    }
  };

  const rewindSong = () => {
    //returns the song to the beginning
    // if(song.currentTime < 5){---------------------------------------------DEPRECATED---------------------------------------------------------
    //   song.currentTime = 0
    // }
    // else if(song.currentTime < 10 && song.currentTime >= 5){
    //   song.currentTime = 5
    // }
    // else if(song.currentTime < 15 && song.currentTime >= 10){
    //   song.currentTime = 10
    // }
    // clearTimeout(losingTimeout.current);
    // losingTimeout.current = null;
    // losingLifeTimeoutFunction(
    //   TIMEOUT_DURATION - ((songTime.current * 1000) % TIMEOUT_DURATION)
    // );
    // setRewindTime(songTime.current)
    setRewindTime((prevState) => {
      if (prevState > songTime.current) {
        return prevState;
      }
      return songTime.current;
    });
    song.currentTime = 0;
    leftTime.current = (TIMEOUT_DURATION / 1000) * 3;
    setSongsLeftTime((TIMEOUT_DURATION / 1000) * 3);
    props.handleClearInterval();

    logEvent(analytics, "select_content_rewind", {
      content_type: "rewind_song",
      item_id: `${props?.songName}`,
      song_duration: `${song?.currentTime}`,
    });
  };

  useImperativeHandle(ref, () => ({
    //???????????????
    textValue,
    setTextValue,
    playSong,
    pauseSong,
    song,
  }));

  const endedSongHandler = () => {
    //using on "ended" event of song
    // losingTimeout.current = null;
    props?.setIsPlaying(false);
  };

  useEffect(() => {
    if (song) {
      song.addEventListener("ended", endedSongHandler);
    }
    return () => song?.removeEventListener("ended", endedSongHandler);
  }, [song]);

  useEffect(() => {
    //if fieldState change from INITAL to this two, it means that that field is over and calls solveField from parent to increment solvedFields, after slovedFields>=2 LINK TEXT FILED shows up!!!
    if (
      // fieldState.current === OVER_FIELD_STATE ||---------------------------------------------DEPRECATED---------------------------------------------------------
      fieldState === CORRECT_FIELD_STATE ||
      fieldState === GAVEUP_FIELD_STATE
    )
      props?.solveField();
  }, [fieldState]);

  useEffect(() => {
    //setSong using props?.songUrl from parent component
    if (props?.songUrl) {
      setSong(new Audio(props?.songUrl));
      if (props.songIndex === 0 && fieldState === INITIAL_FIELD_STATE) {
        props?.setCurrentSongUrl(props.songUrl);
      }
    }
  }, [props?.songUrl]);
  const changeTextValue = (value) => {
    //handler for texting into input...can't texting if: 1.song didn't play at least one; 2.field is Solved and disabled; texting makes song paused
    if (
      fieldState !== CORRECT_FIELD_STATE &&
      fieldState !== GAVEUP_FIELD_STATE &&
      props?.isLoaded &&
      !props?.disabled
    ) {
      setTextValue(value);
    }
  };

  const guessSong = (guessedSong) => {
    //going deep into guessTextField
    setTextValue(guessedSong);
    dispatch(
      fetchGuessSong({
        answer: guessedSong,
        id: props?.songId,
      })
    );
    // if (guessLives?.current > 0){---------------------------------------------DEPRECATED---------------------------------------------------------
    if (guessedSong === props?.songName) {
      setFieldState(CORRECT_FIELD_STATE);
      pauseSong();
      props?.setIsPlaying(false);

      logEvent(analytics, "select_content_correct_answer", {
        content_type: "correct_answer",
        item_id: `${guessedSong}`,
        song_duration: `${song?.currentTime}`,
      });
    } else {
      setTextValue("");
      setFieldState(WRONG_FIELD_STATE);
      logEvent(analytics, "select_content_wrong_answer", {
        content_type: "wrong_answer",
        item_id: `${guessedSong}`,
        song_duration: `${song?.currentTime}`,
      });
      // if(guessLives?.current === 1){---------------------------------------------DEPRECATED---------------------------------------------------------
      //   loseField()
      //   props?.loseLife();
      //   fieldState.current = OVER_FIELD_STATE;
      //   setTextValue("Wrong - X to reveal (loses 3 points)");
      // }
      // else{
      //   fieldState.current = WRONG_FIELD_STATE;
      //   setTextValue("Try again (1 point lost)");
      //   guessLives.current = guessLives?.current - 1;
      //   props?.loseLife();
      // }
    }
    // }
  };

  // const losingLifeTimeoutFunction = useCallback(//after TIMEOUT_DURATION calling loseLife()
  //   (timeoutTime) => {---------------------------------------------DEPRECATED---------------------------------------------------------
  //     losingTimeout.current =
  //       setTimeout(() => {
  //         if (fieldLives?.current > 0) {
  //           loseLife();
  //         }
  //         if (!song?.paused) losingLifeTimeoutFunction(TIMEOUT_DURATION);
  //       }, Math.ceil(timeoutTime))
  //   },
  //   [fieldLives?.current, song, loseLife]
  // );

  const focusHandler = () => {
    if (!song.paused) {
      playSong();
      props?.setIsPlaying(false);
      setPlayerStatus(false);
    }
  };

  const playSong = () => {
    if (props?.blockingGame) return;
    // if(!leftTime.current){
    //   return
    // }odavde kreni dalje...postoji problem pri ponovnom pustanju pesme!!!
    if (!props?.disabled) {
      if (song.paused) {
        if (!isLoaded) setIsLoaded(true);
        props?.playSong(props?.songIndex);
        // song.play();
        // losingLifeTimeoutFunction(
        //   TIMEOUT_DURATION - ((songTime.current * 1000) % TIMEOUT_DURATION)
        // );
        props?.setIsPlaying(true);
        // if(props.scoreTimers < 15){--------------------------------------------mozda je reseno!!!
        //   props.handleSetDecimalInterval()
        // }
        logEvent(analytics, "select_content_play", {
          content_type: "play_song",
          item_id: `${props?.songName}`,
        });
      } else {
        // song.pause();
        // clearTimeout(losingTimeout.current);
        // losingTimeout.current = null;
        props?.setIsPlaying(false);
      }
    }
  };
  const playSongForFun = () => {
    if (props?.blockingGame) return;
    if (song.paused) {
      if (!isLoaded) setIsLoaded(true);
      props?.playSong(props?.songIndex);
      song.play();
      // losingLifeTimeoutFunction(
      //   TIMEOUT_DURATION - ((song.currentTime * 1000) % TIMEOUT_DURATION)
      // );
      props?.setIsPlaying(true);
    } else {
      song.pause();
      // clearTimeout(losingTimeout);
      // setLosingTimeout(null);
      props?.setIsPlaying(false);
    }
    // Yes stop the music when the user clicks on the text field
    props?.pauseMusic();
  };
  const activatePlayer = () => {
    //when text field is clicked information of songIndex will be send to parent to enable that player and disable all others
    if (
      fieldState === CORRECT_FIELD_STATE ||
      fieldState === OVER_FIELD_STATE ||
      fieldState === GAVEUP_FIELD_STATE
    ) {
      playSongForFun();
      return;
    }

    props?.togglePlayers(props?.songIndex);
    song.pause();
    props.handleClearInterval();
  };
  const songTimeLeft = useMemo(() => {
    //sending song to the child component
    if (song) {
      return song;
    }
  }, [song]);
  const handleSetSongsLeftTime = (time) => {
    //activates from a child to update how much time left of the song (total time = 15sec)
    setSongsLeftTime(time);
  };

  const songTimeTracker = () => {
    // if (
    //   fieldState === INITIAL_FIELD_STATE ||
    //   fieldState === WRONG_FIELD_STATE
    // ) {
    //   songTime.current = song.currentTime > 15 ? 15 : song.currentTime;
    //   leftTime.current =
    //     (TIMEOUT_DURATION / 1000) * 3 - songTime.current < 0
    //       ? 0
    //       : (TIMEOUT_DURATION / 1000) * 3 - songTime.current;
    //   props.updateScoreTimers(songTime.current, props.songIndex);
    //   // let floorTime = Math.floor(leftTime.current)---------------------------------------------DEPRECATED---------------------------------------------------------
    //   // if(floorTime === 9 && !lifeLoseSwitcher.current){
    //   //   props?.loseLife();
    //   //   lifeLoseSwitcher.current = !lifeLoseSwitcher.current
    //   // }
    //   // if(floorTime === 4 && lifeLoseSwitcher.current){
    //   //   props?.loseLife();
    //   //   lifeLoseSwitcher.current = !lifeLoseSwitcher.current
    //   // }
    //   // if(floorTime === -1 && !lifeLoseSwitcher.current){
    //   //   props?.loseLife();
    //   //   lifeLoseSwitcher.current = !lifeLoseSwitcher.current
    //   //   props?.setIsPlaying(false)
    //   //   setPlayerStatus(false)
    //   //   song?.pause();
    //   //   fieldLives.current = 0
    //   //   fieldState.current = TIMEOUT_FIELD_STATE
    //   //   setTextValue('Time up! Pass or X to reveal (for -3 points)');
    //   // }
    //   if (leftTime.current === 10) {
    //     console.log("Time out");
    //     song?.pause();
    //     // fieldLives.current = 0---------------------------------------------DEPRECATED---------------------------------------------------------
    //     setFieldState(TIMEOUT_FIELD_STATE);
    //     props?.setIsPlaying(false);
    //     setPlayerStatus(false);
    //     // const newAnswersTimeOutStatus = props.answersTimeOutStatus.map(
    //     //   (item) => item
    //     // );
    //     // newAnswersTimeOutStatus[props.songIndex] = true;
    //     // props.setAnswersTimeOutStatus(newAnswersTimeOutStatus);
    //     // setTextValue('Replay, Guess or X to Reveal (adds 20secs)');
    //   }
    // }
  };

  useLayoutEffect(() => {
    //listen to song time update and send new LEFT-TIME to parent component

    if (
      fieldState !== INITIAL_FIELD_STATE &&
      fieldState !== WRONG_FIELD_STATE &&
      fieldState !== TIMEOUT_FIELD_STATE
    )
      return;
    if (song && !props.disabled) {
      // song.addEventListener("timeupdate", songTimeTracker);
      return () => {
        // song.removeEventListener("timeupdate", songTimeTracker);
      };
    }
  }, [song, props.disabled, fieldState]);

  useEffect(() => {
    if (
      props?.answersTimeOutStatus[props.songIndex] &&
      fieldState === INITIAL_FIELD_STATE
    ) {
      setFieldState(TIMEOUT_FIELD_STATE);
      props?.setIsPlaying(false);
      setPlayerStatus(false);
    }
  }, [props?.answersTimeOutStatus]);

  useEffect(() => {
    if (
      props?.isGaveUpFields[props.songIndex] &&
      fieldState === TIMEOUT_FIELD_STATE
    ) {
      giveUp();
    }
  }, [props.isGaveUpFields]);

  // useEffect(() => {
  //   if (songTime.current === 12) {
  //     props.handleClearInterval();
  //     props.setDecimalTimeToZero();
  //   }
  // }, [songTime.current]);

  // useEffect(() => {
  //   if (props.scoreTimers && !song.paused) {
  //     props.handleSetDecimalInterval();
  //   }
  // }, [props.scoreTimers]);

  useEffect(() => {
    if (props.disabled) {
      if (
        fieldState === INITIAL_FIELD_STATE ||
        fieldState === TIMEOUT_FIELD_STATE ||
        fieldState === WRONG_FIELD_STATE
      ) {
        setFieldState(GAVEUP_FIELD_STATE);
      }
    }
  }, [props.disabled]);
  return (
    <GuessSongFieldContainer>
      <GuessTextField
        handleGuessSong={guessSong}
        clue={props?.cluesRevealed ? clue : titleOrArtistClue}
        solved={
          // fieldState?.current === OVER_FIELD_STATE ||
          fieldState === CORRECT_FIELD_STATE
        }
        unSolved={
          fieldState === GAVEUP_FIELD_STATE ||
          // fieldState?.current === OVER_FIELD_STATE ||
          fieldState === TIMEOUT_FIELD_STATE
        }
        setTextValue={setTextValue}
        setFieldState={setFieldState}
        searchSongs={
          textValue !== "Please try again" &&
          // fieldState.current !== OVER_FIELD_STATE &&
          fieldState !== CORRECT_FIELD_STATE &&
          textValue?.length !== 0
        }
        value={textValue}
        hideSuggestions={
          // fieldLives?.current === 0 ||
          fieldState === CORRECT_FIELD_STATE ||
          fieldState === GAVEUP_FIELD_STATE
          // fieldState.current === OVER_FIELD_STATE
        }
        changeTextValue={changeTextValue}
        onFocus={focusHandler}
        setCurrentSongUrl={props.setCurrentSongUrl}
        setCurrentIndex={props.setCurrentIndex}
        currentIndex={props.currentIndex}
        songUrl={props?.songUrl}
        setPlayerStatus={setPlayerStatus}
        backgroundColor={
          fieldState === OVER_FIELD_STATE
            ? selectedTheme.colors.primaryGrey
            : fieldState === CORRECT_FIELD_STATE &&
              selectedTheme.colors.primaryPurple
        }
        onClick={activatePlayer}
        readOnly={props?.isLoaded}
        fieldState={fieldState}
        songIndex={props?.songIndex}
        activatePlayer={activatePlayer}
        pauseMusic={props?.pauseMusic}
        isPlaying={props?.isPlaying}
      />
      <InvisiblePlayerWrapper>
        {props?.activePlayer[props?.songIndex] && !props?.disabled && (
          <PlayerCommandsContainer>
            {/* <PlayerController
              playerStatus={playerStatus}
              setPlayerStatus={setPlayerStatus}
              playSong={playSong}
              pauseSong={pauseSong}
              rewindSong={rewindSong}
              playerControllerStatus={playerControllerStatus}
              fieldState={fieldState}
              // playSongForFun={playSongForFun}
            /> */}
            {/* {
              //fieldState.current !== OVER_FIELD_STATE &&
              fieldState !== CORRECT_FIELD_STATE &&
                fieldState !== GAVEUP_FIELD_STATE && (
                  <>
                    <DiscLosingProgress //noOfLives={fieldLives?.current}
                      song={songTimeLeft}
                      songsLeftTime={songsLeftTime}
                      setSongLeftTime={handleSetSongsLeftTime} //losingLife={losingTimeout}
                      leftTime={leftTime.current}
                      rewindTime={rewindTime}
                    />
                    <CircleCommand
                      onClick={giveUp}
                      firstCommand={<MelodyIcon />}
                      secondCommand={<EyeIcon />}
                    />
                  </>
                )
            } */}
          </PlayerCommandsContainer>
        )}
      </InvisiblePlayerWrapper>
    </GuessSongFieldContainer>
  );
});

GuessSongField.displayName = "GuessSongField";

GuessSongField.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  songUrl: PropTypes.string,
  songName: PropTypes.string,
  hint: PropTypes.string,
  titleOrArtistHint: PropTypes.any,
  wrong: PropTypes.bool,
  correct: PropTypes.bool,
  cluesRevealed: PropTypes.bool,
  titleOrArtistCluesRevealed: PropTypes.bool,
  disabled: PropTypes.bool,
  loseLife: PropTypes.func,
  solveField: PropTypes.func,
  songId: PropTypes.string,
  playSong: PropTypes.func,
  songIndex: PropTypes.number,
  setIsPlaying: PropTypes.func,
  activePlayer: PropTypes.any,
  togglePlayers: PropTypes.func,
  updateScoreTimers: PropTypes.func,
  giveUpScoreTimers: PropTypes.func,
  handleSetDecimalInterval: PropTypes.func,
  handleClearInterval: PropTypes.func,
  setDecimalTimeToZero: PropTypes.func,
  scoreTimers: PropTypes.number,
  blockingGame: PropTypes.bool,
  setCurrentSongUrl: PropTypes.func,
  setCurrentIndex: PropTypes.func,
  currentIndex: PropTypes.number,
  pauseMusic: PropTypes.func,
  isLoaded: PropTypes.bool,
  answersTimeOutStatus: PropTypes.array,
  setAnswersTimeOutStatus: PropTypes.func,
  isGaveUpFields: PropTypes.array,
  isPlaying: PropTypes.bool,
};

export default GuessSongField;

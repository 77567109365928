import React, { useMemo } from "react";
import PropTypes from "prop-types";
import {
  PlayerLivesContainer,
  PlayerLivesLine,
  SinglePlayerLive,
  GameOverLives,
  SecContainer,
  XIcon,
} from "./PlayerLives.styled";
import { HAS_LIFE, NO_LIFE } from "../../constants/livesConstants";
// import { ORANGE_DISC } from "../../constants/discColors";
import LifeBar from "../Bars/LifeBar/LifeBar";

const PlayerLives = (props) => {
  const lives = useMemo(() => {
    let firstLine = [];
    let secondLine = [];
    for (let i = 0; i < 15; i++) {
      secondLine.push(props?.noOfLives > i ? HAS_LIFE : NO_LIFE);
      firstLine.push(props?.noOfLives > i + 15 ? HAS_LIFE : NO_LIFE);
    }
    return {
      firstLine,
      secondLine,
    };
  }, [props?.noOfLives]);
  return (
    <PlayerLivesContainer>
      <PlayerLivesLine>
        {/* &nbsp; */}
        {[...lives.secondLine, ...lives.firstLine].map((item, index) => (
          <SinglePlayerLive key={index}>
            {item === HAS_LIFE ? (
              <LifeBar heightIndex={index % 9} isPlaying={props?.isPlaying} />
            ) : (
              // <LifeBar isEmpty />//if client would like to restore previous setting just uncomment this!!!
              <LifeBar heightIndex={index % 9} isPlaying={props?.isPlaying} />
            )}
          </SinglePlayerLive>
        ))}
        {/* &nbsp; */}
      </PlayerLivesLine>
      {/* <PlayerLivesLine>
        {lives?.secondLine?.map((item, index) => (
          <SinglePlayerLive key={index}>
            {item === HAS_LIFE ? <LifeBar heightIndex={} /> : <></>}
          </SinglePlayerLive>
        ))}
      </PlayerLivesLine> */}
      {props?.isShowTimer && props?.timerStatus && props.gameState !== 1 && (
        <GameOverLives $gameOver={props?.gameOver}>
          <SecContainer>{`${props?.scoreTimer}`}</SecContainer>
          {`.${props?.decimalTime}`}
        </GameOverLives>
      )}
      {props.gameState === 1 && (
        <GameOverLives $gameOver={props?.gameOver}>
          <XIcon />
        </GameOverLives>
      )}
    </PlayerLivesContainer>
  );
};

PlayerLives.propTypes = {
  noOfLives: PropTypes.number,
  scoreTimer: PropTypes.number,
  isPlaying: PropTypes.bool,
  gameOver: PropTypes.bool,
  timerStatus: PropTypes.bool,
  decimalTime: PropTypes.number,
  gameState: PropTypes.number,
  isShowTimer: PropTypes.bool,
};

export default PlayerLives;

import React from "react";
import PropTypes from "prop-types";
import { IconContainer, Help, About, Submit } from "./DetailsIcon.styles";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../index";
// import { useTranslation } from "react-i18next";

const DetailsIcon = (props) => {
    // const { t } = useTranslation()
    const handleSetCardType = (type) => {
        if(props?.blockingGame) return
        props?.setCardType(type)
        logEvent(analytics, `select_content_${type}`, {
            content_type: `${type}_card`
        }) 
    }

    return(
        <>
            {props?.cardType==='help' && 
            <IconContainer onClick={() => handleSetCardType('help')}>
                <Help />
                {/* <h3>{t("common.help")}</h3> */}
            </IconContainer>}
            {props?.cardType==='about' && 
            <IconContainer onClick={() => handleSetCardType('about')}>
                <About />
                {/* <h3>{t("common.about")}</h3> */}
            </IconContainer>}
            {props?.cardType==='submit' && 
            <IconContainer onClick={() => handleSetCardType('submit')}>
                <Submit />
                {/* <h3>{t("common.submit")}</h3> */}
            </IconContainer>}
        </>
    )
}


DetailsIcon.propTypes = {
    cardType: PropTypes.string,
    setCardType: PropTypes.func,
    blockingGame: PropTypes.bool
}

export default DetailsIcon
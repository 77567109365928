import { Box } from "@mui/material";
import styled from "styled-components";

export const GameTitleContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: end;
  font-family: Calibri, sans-serif;
  color: white;
  margin-bottom: 20px;
  gap: 5px;
`;
export const GameLogo = styled("img")`
  width: 30px;
  @media screen and (max-width: 600px) {
    width: 35px;
  }
`;
export const GameSecondaryLogo = styled("img")`
  width: 30px;
  @media screen and (max-width: 600px) {
    width: 35px;
  }
`;

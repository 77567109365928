import React, { useEffect, useRef, useState, useMemo } from "react";
import PropTypes from "prop-types";
import {
  GameDescription,
  GuessesContainer,
  PlayContentContainer,
  HelpAboutContainer,
} from "./PlayContent.styled";
import PlayerLives from "../../components/PlayerLives/PlayerLives";
import GameTitle from "../../components/GameTitle/GameTitle";
import GuessSongField from "../../components/GuessSongField/GuessSongField";
import { useDispatch, useSelector } from "react-redux";
import { selectGame } from "../../store/selectors/gameSelectors";
import { fetchGame } from "../../store/actions/game/gameActions";
import LinkTextField from "../../components/TextField/LinkTextField/LinkTextField";
import { useTranslation } from "react-i18next";
import DetailsIcon from "../../components/DetailsIcon/DetailsIcon";
import DetailsCard from "../../components/DetailsCard/DetailsCard";
import PlayerController2 from "../../components/PlayerController/PlayerController2";
import FooterHistory from "../../components/Footer/FooterHistory";
import Sponsor from "../../components/Sponsor/Sponsor";

import gameStates from "../../constants/gameStates";

const PlayContent = () => {
  //here is whole logic of application
  const { t } = useTranslation(); //i18n english translation
  const game = useSelector(selectGame); //pulling game
  const [hints, setHints] = useState({}); //hints for the game
  const [graphics, setGraphics] = useState({}); //graphics for the game

  const songRefs = useRef([null, null, null, null]); //four refs with index
  const [lives, setLives] = useState(30); //user's life
  const [scoreTimers, setScoreTimers] = useState([0, 0, 0, 0, 0]); //user's final score because we will not use lives above anymore!!!!!!!!!!!!!!!!
  const [currentIndex, setCurrentIndex] = useState(0); //index of the song
  const timerStatus = useRef(false);
  const [solvedFields, setSolvedFields] = useState(0); //solved player's fields...2 or more reveals FINAL QUESTION
  const [cluesRevealed, setCluesRevealed] = useState(false); //revealing keywords in association
  const [titleOrArtistCluesRevealed, setTitleOrArtistCluesRevealed] =
    useState(false); //revealing keywords in association
  const [toggleClues, setToggleClues] = useState(false); //hidding and revealing keywords---connected with cluesRevealed
  const [toggleTitleOrArtistClues, setToggleTitleOrArtistClues] =
    useState(false); //hidding and revealing keywords---connected with titleOrArtistCluesRevealed
  const [gameOver, setGameOver] = useState(false); //disabling game
  const [isPlaying, setIsPlaying] = useState(false); //status activated by single player and used for lifeBar animation
  const [activePlayer, setActivePlayer] = useState([true, false, false, false]); //only one player can be displayed and it activates clicking on the text field
  const [cardType, setCardType] = useState("");
  const [currentSongUrl, setCurrentSongUrl] = useState("");
  const [decimalTime, setDecimalTime] = useState(0);
  const [decimalInterval, setDecimalInterval] = useState(-1);
  const PlayerController2Ref = useRef();
  const [isShowTimer, setIsShowTimer] = useState(false);
  const [isLoadedSongs, setIsLoadedSongs] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [answersTimeOutStatus, setAnswersTimeOutStatus] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [isGaveUpFields, setIsGaveUpFields] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [gameState, setGameState] = useState(gameStates.INITIAL);
  // const [blockingGame, setBlockingGame] = useState(false)//blocking every functionality inside the app if user REFRESH PAGE and try to do something!!!

  // const [finalScore, setFinalScore] = useState(0)
  const dispatch = useDispatch();

  //   useEffect(() => {
  //     const player = JSON.parse(localStorage.getItem('player'))

  //     if(player){
  //       let date = new Date()
  //       if(player.day === date.getDate() && player.month === date.getMonth() && player.year === date.getFullYear()){
  //         setGameOver(true)
  //         setCardType('score')
  //         setFinalScore(player.score)
  //         setGameState(player.gameState)
  //         setBlockingGame(true)
  //       }
  //     }
  //   }, [])
  // // ----------------------------------------------------- these two useEffects will be used for production------------------------------------------------
  //   useEffect(() => {
  //     const player = JSON.parse(localStorage.getItem('player'))
  //     if(player){
  //       let date = new Date()
  //       if(player.day === date.getDate() && player.month === date.getMonth() && player.year === date.getFullYear()){
  //         return
  //       }
  //     }

  //     if(gameOver){
  //       const time = new Date()
  //       const player = {
  //         score: (scoreTimer + (decimalTime/10)),
  //         year: time.getFullYear(),
  //         month: time.getMonth(),
  //         day: time.getDate(),
  //         gameState: gameState
  //       }

  //       localStorage.setItem('player',JSON.stringify(player))
  //     }
  //   }, [gameOver])

  useEffect(() => {
    if (JSON.stringify(hints) === "{}" && game) {
      const hints_data = {
        things_or_phrases: game.songs[0].hints.find(
          // (hint) => hint.songHintType.name === "Things or Phrases"
          (hint) => hint.name === "Things or Phrases"
        ),
        title_or_artist: game.songs.map(
          (song) =>
            song.hints.find(
              // (hint) => hint.songHintType.name === "Title or Artist"
              (hint) => hint.name === "Title or Artist"
            ).hint
        ),
        highlight_words: game.songs.map(
          (song) =>
            song.hints.find(
              // (hint) => hint.songHintType.name === "Highlight Word"
              (hint) => hint.name === "Highlight Word"
            ).hint
        ),
      };
      setHints(hints_data);
    }
    if (game) {
      const {
        footerLogoUrl = "",
        footerText = "",
        masterHead = "",
        primaryLogoUrl = "",
        secondaryLogoUrl = "",
        primaryText = "",
      } = game.graphics;
      // const graphics_data = {
      //   footerLogoUrl:
      //     "https://cdn.hashnode.com/res/hashnode/image/upload/v1647490619965/P1dsNgj-f1.png",
      //   footerText: "Today's game is sponsored by",
      //   masterHead: "This is master head",
      //   primaryLogoUrl:
      //     "https://cdn.hashnode.com/res/hashnode/image/upload/v1647490619965/P1dsNgj-f1.png",
      //   secondaryLogoUrl:
      //     "https://seeklogo.com/images/R/react-logo-65B7CD91B5-seeklogo.com.png",
      //   primaryText: "Primary text",
      // };
      setGraphics({
        footerLogoUrl,
        footerText,
        masterHead,
        primaryLogoUrl,
        secondaryLogoUrl,
        primaryText,
      });
      setIsShowTimer(game.shouldShowTimer);
    }
  }, [game]);

  useEffect(() => {
    //fetching game on mounted
    dispatch(fetchGame());
    const isPlayed = localStorage.getItem("played");
    if (!isPlayed) {
      setCardType("help");
      localStorage.setItem("played", JSON.stringify(true));
    }
  }, []);

  useEffect(() => {
    handleClearInterval();
  }, [answersTimeOutStatus]);
  const loseLife = () => {
    //calling from child as a prop
    if (lives > 0) setLives((prevLives) => prevLives - 1);
  };
  const updateScoreTimers = (time, index) => {
    // this updates scoreTimer regular with song time
    setScoreTimers((prevState) =>
      prevState.map((el, idx) => {
        if (idx === index) {
          if (el <= time) {
            return time;
          } else {
            return el;
          }
        } else {
          return el;
        }
      })
    );
  };
  const giveUpScoreTimers = (index) => {
    setScoreTimers((prevState) => {
      console.log(
        prevState.map((el, idx) => {
          if (idx === index) {
            return (el += 20);
          } else {
            return el;
          }
        })
      );
      return prevState.map((el, idx) => {
        if (idx === index) {
          return (el += 20);
        } else {
          return el;
        }
      });
    });
  };
  const scoreTimer = useMemo(() => {
    let score = Math.floor(scoreTimers.reduce((sum, el) => (sum += el)));
    return score;
  }, [scoreTimers]);

  const updateTimerStatus = () => {
    if (timerStatus.current) return;
    timerStatus.current = true;
  };

  const solveField = () => {
    //calling from child as a prop
    setSolvedFields((prevSolvedFields) => prevSolvedFields + 1);
  };
  const revealClues = () => {
    //calling from child as a prop
    if (!cluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setCluesRevealed(true);
      setScoreTimers((prevState) =>
        prevState.map((el, idx) => {
          if (idx === prevState.length - 1) {
            if (el === 0) {
              return (el += 10);
            }
          } else {
            return el;
          }
        })
      );
    }
    setToggleClues((prevState) => !prevState);
  };

  const revealTitleOrArtistClues = () => {
    if (!titleOrArtistCluesRevealed && !gameOver) {
      setLives((prevLives) => prevLives - 3);
      setTitleOrArtistCluesRevealed(true);
    }
  };

  const revealClueHint = () => {
    if (solvedFields < 3) return;
    setCluesRevealed(true);
    setToggleClues(true);
  };

  const revealTitleOrArtistClueHint = () => {
    if (solvedFields < 3) return;
    setTitleOrArtistCluesRevealed(true);
    setToggleTitleOrArtistClues(true);
  };

  const revealHints = (addingTime) => {
    if (!gameOver && addingTime) {
      const newScoreTimers = scoreTimers.map((item) => item);
      newScoreTimers.push(Number(addingTime));
      setScoreTimers(newScoreTimers);
    }
  };

  const playSong = (indexOfSong) => {
    //calling from child as a prop...only one can play
    songRefs?.current?.forEach((singleSongRef, index) => {
      if (index !== indexOfSong) {
        singleSongRef.pauseSong();
        singleSongRef.song.pause();
      }
    });
  };
  const togglePlayers = (index) => {
    //hiding all players except that one that is clicked on the text field, and that information comes from the child component
    setActivePlayer(
      activePlayer.map((el, idx) => {
        if (idx !== index) {
          return (el = false);
        } else {
          return (el = true);
        }
      })
    );
    playSong(index);
  };
  const handleSetDecimalInterval = () => {
    if (decimalInterval !== null && decimalInterval > 0) {
      return;
    }
    setDecimalInterval(
      setInterval(() => {
        setDecimalTime((prevState) => {
          return (prevState + 1) % 10;
        });
      }, 100)
    );
  };

  const handleClearInterval = () => {
    if (decimalInterval) {
      clearInterval(decimalInterval);
      setDecimalInterval(null);
    }
  };

  const setDecimalTimeToZero = () => {
    setDecimalTime(0);
  };

  const handleTimeUpController = () => {
    handleClearInterval();
    setDecimalTimeToZero();
  };
  useEffect(() => {
    if (gameOver) {
      setCluesRevealed(true);
      setToggleClues(true);
    }
  }, [gameOver]);

  const handleSetGameState = (state) => {
    setGameState(state);
  };

  const pauseMusic = () => {
    if (PlayerController2Ref.current) {
      PlayerController2Ref.current.onPauseChildren();
    }
  };
  return (
    <PlayContentContainer>
      <GameTitle graphics={graphics} />
      <PlayerLives
        noOfLives={lives}
        isPlaying={isPlaying}
        gameOver={gameOver}
        scoreTimer={scoreTimer}
        timerStatus={timerStatus.current}
        decimalTime={decimalTime}
        gameState={gameState}
        isShowTimer={isShowTimer}
      />
      <GameDescription>
        {/* {t("game.description")} */}
        {graphics.masterHead || t("game.description")}
      </GameDescription>
      <GuessesContainer onClick={updateTimerStatus}>
        {JSON.stringify(hints) !== "{}" &&
          game?.songs?.map((singleSong, index) => (
            <GuessSongField
              cluesRevealed={toggleClues}
              titleOrArtistCluesRevealed={toggleTitleOrArtistClues}
              loseLife={loseLife}
              playSong={playSong}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
              disabled={gameOver || isGaveUpFields[index]}
              ref={(elRef) => (songRefs.current[index] = elRef)}
              solveField={solveField}
              key={singleSong?.id}
              songUrl={singleSong?.url}
              songIndex={index}
              hint={hints.highlight_words[index]}
              titleOrArtistHint={hints.title_or_artist[index]}
              songName={singleSong?.name}
              artist={singleSong?.artist}
              songId={singleSong?.id}
              activePlayer={activePlayer}
              togglePlayers={togglePlayers}
              scoreTimers={scoreTimers[index]}
              updateScoreTimers={updateScoreTimers}
              giveUpScoreTimers={giveUpScoreTimers}
              handleSetDecimalInterval={handleSetDecimalInterval}
              handleClearInterval={handleClearInterval}
              setDecimalTimeToZero={setDecimalTimeToZero}
              setCurrentSongUrl={setCurrentSongUrl}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
              pauseMusic={pauseMusic}
              isLoaded={isLoadedSongs[index]}
              answersTimeOutStatus={answersTimeOutStatus}
              setAnswersTimeOutStatus={setAnswersTimeOutStatus}
              isGaveUpFields={isGaveUpFields}
              // blockingGame={blockingGame}
            />
          ))}
        {!gameOver && game?.songs?.length > 0 && solvedFields < 4 && (
          <PlayerController2
            disabled={gameOver}
            ref={PlayerController2Ref}
            currentSongUrl={currentSongUrl}
            handleSetDecimalInterval={handleSetDecimalInterval}
            handleClearInterval={handleClearInterval}
            setDecimalTimeToZero={setDecimalTimeToZero}
            scoreTimers={scoreTimers}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            updateScoreTimers={updateScoreTimers}
            giveUpScoreTimers={giveUpScoreTimers}
            setIsLoadedSongs={setIsLoadedSongs}
            isLoadedSongs={isLoadedSongs}
            answersTimeOutStatus={answersTimeOutStatus}
            setAnswersTimeOutStatus={setAnswersTimeOutStatus}
            handleTimeUpController={handleTimeUpController}
            setIsPlaying={setIsPlaying}
            isGaveUpFields={isGaveUpFields}
            setIsGaveUpFields={setIsGaveUpFields}
          />
        )}
      </GuessesContainer>
      <LinkTextField
        answers={game?.answers}
        loseLife={loseLife}
        totalLives={lives}
        questionId={game?.id}
        gameOver={() => setGameOver(true)}
        revealClues={revealClues}
        cluesRevealed={cluesRevealed}
        titleOrArtistCluesRevealed={titleOrArtistCluesRevealed}
        revealTitleOrArtistClues={revealTitleOrArtistClues}
        solvedFields={solvedFields}
        scoreTimer={scoreTimer}
        handleSetGameState={handleSetGameState}
        decimalTime={decimalTime}
        disabled={gameOver}
        revealHints={revealHints}
        hints={hints}
        revealClueHint={revealClueHint}
        revealTitleOrArtistClueHint={revealTitleOrArtistClueHint}
      />
      {cardType === "help" && (
        <DetailsCard cardType="help" setCardType={setCardType} />
      )}
      {cardType === "submit" && (
        <DetailsCard cardType="submit" setCardType={setCardType} />
      )}
      {cardType === "about" && (
        <DetailsCard cardType="about" setCardType={setCardType} />
      )}
      {cardType === "score" && (
        <DetailsCard
          cardType="score"
          // score={finalScore}
          gameState={gameState}
        />
      )}
      <div
        style={{
          borderTop: "2px solid #fff",
          paddingTop: "20px",
          marginTop: "26px",
        }}
      >
        {(gameState === gameStates.WON || gameState === gameStates.LOST) && (
          <FooterHistory />
        )}
        <HelpAboutContainer>
          <DetailsIcon
            cardType="help"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="submit"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
          <DetailsIcon
            cardType="about"
            setCardType={setCardType}
            // blockingGame={blockingGame}
          />
        </HelpAboutContainer>
      </div>
      <Sponsor graphics={graphics} />
    </PlayContentContainer>
  );
};

PlayContent.propTypes = {
  children: PropTypes.node,
};

export default PlayContent;

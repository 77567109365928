export const fieldStatesColors = {
  INITIAL_FIELD_STATE: {
    backgroundColor: "#FFFFFF1C",
    color: "#FFFFFF",
  },
  GAVEUP_FIELD_STATE: {
    backgroundColor: "#e3ffd4",
    color: "#000000",
  },
  TIMEOUT_FIELD_STATE: {
    backgroundColor: "#e72401",
    color: "#FFF",
  },
  CORRECT_FIELD_STATE: {
    backgroundColor: "#B6DBA2",
    color: "#000000",
  },
  WRONG_FIELD_STATE: {
    backgroundColor: "#FF6452",
    color: "#FFFFFF",
  },
  OVER_FIELD_STATE: {
    backgroundColor: "#FF6452",
    color: "#FFFFFF",
  },
  CURRENT_FIELD_STATE: {
    backgroundColor: "#6253DB",
    color: "#FFFFFF",
  },
};

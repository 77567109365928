import { Box } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
// import selectedTheme from "../../../themes";

// const Waving = keyframes`
//   10%{ background-position: 0% 10%;}
//   20%{ background-position: 0% 40%;}
//   30%{ background-position: 0% 20%;}
//   50%{ background-position: 0% 60%;}
//   75%{ background-position: 0% 70%;}
//   100%{ background-position: 0% 10%;}
// `;
const Waving = keyframes`
  0% {height: 100%}
 25% {height: 0%}
  50% {height: 100%}
  75% {height: 0%}
  100% {height: 100%}
`;

export const LifeBarContainer = styled(Box)`
  height: 75px;
  width: calc(400px / 100 * 2);
  background: #6b50e9;
  ${(props) =>
    props?.$isEmpty
      ? "none"
      : `linear-gradient(360deg,#6B50E9 ${
          80 - (50 - Math.abs(((5 - props?.$heightIndex) / 5) * 40))
        }%,#201E42 98%)`};
  border: ${(props) => (props?.$isEmpty ? "1px solid #6B50E9" : "none")};
  background-size: 150% 150%;
  background-repeat: no-repeat;
  ${(props) =>
    props?.$isPlaying &&
    css`
      animation: ${Waving} 1.5s ${props?.$heightIndex * 0.1}s ease infinite;
    `}
  @media (max-width: 600px) {
    width: 2vw;
  }
`;

// export const LifeBarFill = styled(Box)`
//   background-color: ${selectedTheme.colors.lifeLineGradient};
//   height: ${(props) =>
//     props?.$isEmpty
//       ? "0"
//       : `${50 - Math.abs(((5 - props?.$heightIndex) / 5) * 40)}%`};
//   width: calc(400px / 100 * 2 - 1px);
//   @media (max-width: 600px) {
//     width: calc(2vw - 1px);
//   }
// `;
